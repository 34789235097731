@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: local('Metropolis Regular'), local('Metropolis-Regular'), url('/assets/fonts/Metropolis-Regular.woff2') format('woff2'), url('/assets/fonts/Metropolis-Regular.woff') format('woff'); }

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 300;
  src: local('Metropolis Light'), local('Metropolis-Light'), url('/assets/fonts/Metropolis-Light.woff2') format('woff2'), url('/assets/fonts/Metropolis-Light.woff') format('woff'); }


@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  src: local('Metropolis Medium'), local('Metropolis-Medium'), url('/assets/fonts/Metropolis-Medium.woff2') format('woff2'), url('/assets/fonts/Metropolis-Medium.woff') format('woff'); }

@font-face {
  font-family: 'Cookie';
  font-style: normal;
  font-weight: 400;
  src: local('Cookie-Regular'), url('/assets/fonts/cookie-v10-latin-regular.woff2') format('woff2'), url('/assets/fonts/cookie-v10-latin-regular.woff') format('woff'); }

@font-face {
  font-family: 'Volkhov';
  font-style: italic;
  font-weight: 400;
  src: local('Volkhov Italic'), local('Volkhov-Italic'), url('/assets/fonts/volkhov-v11-latin-italic.woff2') format('woff2'), url('/assets/fonts/volkhov-v11-latin-italic.woff') format('woff'); }

// @font-face
//   font-family: 'Volkhov'
//   font-style: italic
//   font-weight: 700
//   src: local('Volkhov Bold Italic'), local('Volkhov-BoldItalic'), url('/assets/fonts/volkhov-v11-latin-700italic.woff2') format('woff2'), url('/assets/fonts/volkhov-v11-latin-700italic.woff') format('woff')
