$padding: 10px;
$margin: 25px;
$font-path: '../fonts';
$icons: '../images/icons/';
$copy-icon-path: '#{$icons}copy.svg';
$next-icon-path: '#{$icons}next.svg';
$reply-icon-path: '#{$icons}reply.svg';
$close-icon-path: '#{$icons}close.svg';
$never-icon-path: '#{$icons}sitting.svg';

// #161d21; // #1F2A2F; // #132433; // #24292e;
html {
  --light: #fff;
  --dark: #131313;
  --gray: #f5f5f5;
  --bubble: #161718;
  --accent: var(--bubble);
  --bg: var(--dark);
  --text: var(--light);
  --theme: #ef7f1a;
  --font: 'Metropolis', sans-serif;
  --italic: 'Volkhov'; } // italic font

%narrow {
  max-width: 750px;
  margin: 0 auto; }

@mixin viewport($width: 1024px, $margin: 25px) {
  max-width: $width;
  margin: $margin auto;
  @content; }

@mixin shadow($opacity: 0.2) {
  box-shadow: 0 0 0.4rem rgba(100,100,100,$opacity);
  &:hover {
    box-shadow: 0 0 1rem rgba(100,100,100, (3 * $opacity)); } }
